import { useContext } from 'react';
import { classNameBannerTitle, varsBannerTitle } from './BannerTitle.css.ts';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { minSize } from '../utils';
import AppContext from '../../../../context/AppContext';
import { MOBILE_MAX_FONT_SIZE } from '../../../../helpers/constants';

export function BannerTitle({ children }) {
  const { attributes } = useContext(AppContext);

  return (
    <div
      className={`${classNameBannerTitle} custom-css-title`}
      style={assignInlineVars(varsBannerTitle, {
        color: attributes.banner_beforeTitleFontColor,
        fontFamily: attributes.banner_beforeTitleFontFamily,
        fontSize: {
          v1: minSize(MOBILE_MAX_FONT_SIZE, attributes.banner_beforeTitleFontSize),
          v2: attributes.banner_beforeTitleFontSize,
        },
        fontStyle: attributes.banner_beforeTitleFontStyle,
        fontWeight: attributes.banner_beforeTitleFontStyle,
        marginBottom: attributes.banner_beforeTitleSpacing,
      })}
    >
      {children}
    </div>
  );
}
