import { useContext } from 'react';
import Condition from '../../../../modules/react_components/common/condition';
import { Container } from '../display/FixedHeader/FixedHeader';
import { CartTimer, Container as CartContainer } from '../display/Cart/Cart';
import { ButtonLayout } from '../ButtonLayout/ButtonLayout';
import { BannerSubtitle } from '../BannerSubtitle/BannerSubtitle';
import { ButtonLink } from '../ButtonLink/ButtonLink';
import { BannerTitle } from '../BannerTitle/BannerTitle';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { classNameMessage, varsMessage } from './BehaviorAfterCount.css.ts';
import AppContext from '../../../../context/AppContext';

export const Message = function ({ children }) {
  const { attributes } = useContext(AppContext);

  return (
    <div
      className={classNameMessage}
      style={assignInlineVars(varsMessage, {
        color: attributes[`${attributes.countdownType}_generalTextFontColor`],
        fontFamily: attributes[`${attributes.countdownType}_generalTextFontFamily`],
        fontSize: attributes[`${attributes.countdownType}_generalTextFontSize`] || '18px',
        fontStyle: attributes[`${attributes.countdownType}_generalTextFontStyle`],
        fontWeight: attributes[`${attributes.countdownType}_generalTextFontStyle`],
      })}
    >
      {children}
    </div>
  );
};

const getAfterCountMessage = (type, message) => {
  if (type === 'fixedHeader') {
    return (
      <Container>
        <Message>{message}</Message>
      </Container>
    );
  }
  return (
    <CartContainer>
      <CartTimer>
        <Message>{message}</Message>
      </CartTimer>
    </CartContainer>
  );
};

function BehaviorAfterCount() {
  const { attributes } = useContext(AppContext);
  if (
    ['empty_cart_hide_counter', 'hide_counter'].includes(attributes[`${attributes.countdownType}_behaviorAfterCount`])
  ) {
    return null;
  }

  if (attributes.countdownType === 'banner') {
    return (
      <div style={{ textAlign: attributes.banner_titleAndSubtitleAlignments }}>
        <BannerTitle id="BannerTitle">{attributes.banner_finishedTitle}</BannerTitle>
        <BannerSubtitle id="BannerSubtitle">{attributes.banner_finishiedSubtitle}</BannerSubtitle>
        <Condition match={attributes.finishedButtonType !== 'none'}>
          <ButtonLayout>
            <ButtonLink target="_blank" href={attributes.finishedButtonLink}>
              {attributes.finishiedButtonText}
            </ButtonLink>
          </ButtonLayout>
        </Condition>
      </div>
    );
  }

  return getAfterCountMessage(attributes.countdownType, attributes[`${attributes.countdownType}_finishedMessage`]);
}

BehaviorAfterCount.propTypes = {};

export default BehaviorAfterCount;
