import { use, useContext, useEffect } from 'react';
import { zeroPad } from 'react-countdown';
import UpgradeHelper from '../../../../../helpers/upgrade_helper';
import Condition from '../../../../../modules/react_components/common/condition';
import { EMOJIS, INTERVAL_UNIT_MS, TIME_PERIODS, TIMER_GOAL_BEHAVIOR } from '../../utils';
import CountdownPowrMark from '../../CountdownPowrMark/CountdownPowrMark';
import AppContext from '../../../../../context/AppContext';
import {
  classNameContainer,
  classNameCounterContainer,
  classNameCounterText,
  classNameCounterValues,
  classNameCross,
  classNameFixedHeaderTimer,
  classNameIcon,
  classNameTextBeforeWithIcon,
  varsContainer,
  varsFixedHeaderTimer,
} from './FixedHeader.css.ts';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { classNameCounter, varsCounter } from '../Cart/Cart.css.ts';
import { POWR_SSR_ID } from '../../../../../helpers/constants';
import { isPlatform, isSsr } from '../../../../../helpers/index';
import {ButtonLinkFixedHeader} from "../../ButtonLinkFixedHeader/ButtonLinkFixedHeader";


const getNavbarHeight = () => {
  const powrOneNavbar = 60;
  if (isPlatform()) return powrOneNavbar;
  if (isSsr()) return 0;
  return document.getElementById('edit-topbar-container')?.offsetHeight;
}

const inMobileOrTabletPreview = (attributes, meta) =>
  meta.is_standalone ? ['768', '360'].includes(attributes.locals.parent_window_width) : false;

const getPositionsAndWidth = (attributes, meta) => {
  let top, left, right, position, width, bottom;
  if (inMobileOrTabletPreview(attributes, meta)) {
    position = 'absolute';
    top = attributes.fixedHeader_position === 'top' ? 0 : 'unset';
    bottom = attributes.fixedHeader_position === 'bottom' ? 0 : 'unset';
    left = 0;
    right = 0;
    width = '100%';
    return { position, top, left, right, width, bottom };
  }

  position = 'fixed';
  if (attributes.fixedHeader_position === 'top') {
    const promobannerHeight = (meta.is_standalone && document.querySelector('.promo-banner')?.offsetHeight) || 0;
    const navbarHeight = getNavbarHeight();
    top = meta.is_standalone && navbarHeight ? `${navbarHeight + promobannerHeight}px` : '0';
    bottom = 'unset';
  } else {
    top = 'unset';
    bottom = 0;
  }
  left = meta.is_standalone ? '400px' : '0';
  right = 'unset';
  width = meta.is_standalone ? 'calc(100% - 400px)' : '100%';
  return { top, left, right, position, width, bottom };
};

export const Container = function ({ children }) {
  const { attributes, meta } = useContext(AppContext);

  const { width, top, left, right, position, bottom } = getPositionsAndWidth(attributes, meta);

  const backgroundColor = attributes.fixedHeader_backgroundGradient
    ? 'initial'
    : attributes.fixedHeader_backgroundColor;
  const backgroundImage = attributes.fixedHeader_backgroundGradient
    ? `linear-gradient(${attributes.fixedHeader_backgroundStartColor}, ${attributes.fixedHeader_backgroundEndColor})`
    : 'initial';

  return (
    <div
      id='fixed-header-container'
      className={`${classNameContainer} custom-css-header-container oembed-iframe-size`}
      style={assignInlineVars(varsContainer, {
        width,
        position,
        top,
        left,
        right,
        bottom,
        backgroundColor,
        backgroundImage,
      })}
    >
      {children}
    </div>
  );
};

const FixedHeaderTimer = function ({ children }) {
  const { attributes } = useContext(AppContext);
  return (
    <div
      className={`${classNameFixedHeaderTimer} custom-css-fix-header-timer`}
      style={assignInlineVars(varsFixedHeaderTimer, {
        color: attributes.fixedHeader_generalTextFontColor,
        fontFamily: attributes.fixedHeader_generalTextFontFamily,
        fontSize: attributes.fixedHeader_generalTextFontSize,
        fontStyle: attributes.fixedHeader_generalTextFontStyle,
        fontWeight: attributes.fixedHeader_generalTextFontStyle,
      })}
    >
      {children}
    </div>
  );
};

const Counter = function ({ children, inMobileOrTabletPreview }) {
  const { attributes } = useContext(AppContext);

  const behavior = TIMER_GOAL_BEHAVIOR[attributes.timerGoal];

  const backgroundColor = attributes.fixedHeader_counterBackgroundGradient
    ? 'initial'
    : attributes.fixedHeader_counterBackgroundColor;
  const backgroundImage = attributes.fixedHeader_counterBackgroundGradient
    ? `linear-gradient(${attributes.fixedHeader_counterBackgroundStartColor}, ${attributes.fixedHeader_counterBackgroundEndColor})`
    : 'initial';
  const padding = inMobileOrTabletPreview ? '6px 9px' : '6px 12px';

  return (
    <div
      className={`${classNameCounter} custom-css-header-counter`}
      style={assignInlineVars(varsCounter, {
        padding,
        border: `${attributes.fixedHeader_counterBorderSize} solid ${attributes.fixedHeader_counterBorderColor}`,
        borderRadius: `${attributes.fixedHeader_counterBorderRadius}`,
        backgroundColor,
        backgroundImage,
        width: `${behavior.type === 'date' ? 'unset' : '44px'}`,
      })}
    >
      {children}
    </div>
  );
};

const CounterContainer = function ({ children }) {
  return <div className={`${classNameCounterContainer} custom-css-counter-container`}>{children}</div>;
};

const CounterValues = function ({ children }) {
  const isServer = typeof window === 'undefined';
  const wrappedChildren = isServer ? <span style={{ visibility: 'hidden' }}>{children}</span> : children;
  return <span className={`${classNameCounterValues} custom-css-counter-values`}>{wrappedChildren}</span>;
};

const Cross = function ({ children }) {
  return <div className={`${classNameCross} custom-css-cross`}>{children}</div>;
};

const TextBeforeWithIcon = function ({ children }) {
  return <div className={`${classNameTextBeforeWithIcon} custom-css-text-with-icon`}>{children}</div>;
};

const Icon = function ({ children }) {
  return <span className={`${classNameIcon} custom-css-fixed-header-icon`}>{children}</span>;
};

const createNumberElements = ({ value, updateFrequency }) => {
  const numberToShow = parseInt(value / updateFrequency);
  const maxElementsAmount = numberToShow.toString().length + 1;
  const numAsString = zeroPad(numberToShow, Math.max(5, maxElementsAmount));
  const containersWithNumbers = [];
  for (let index = 0; index < numAsString.length; index++) {
    containersWithNumbers.push(
      <Counter key={index}>
        <CounterValues>{numAsString[index]}</CounterValues>
      </Counter>
    );
  }
  return containersWithNumbers;
};

const CounterText = function ({ children }) {
  return <span className={classNameCounterText}>{children}</span>;
};

/* eslint-disable react/prop-types */
export default function FixedHeader(timeProps) {
  const {
    attributes,
    meta,
    params: { powrUrl },
    shadowRoot,
    globals,
  } = useContext(AppContext);

  const behavior = TIMER_GOAL_BEHAVIOR[attributes.timerGoal];
  const behaviorAfterCount = attributes[`${attributes.countdownType}_behaviorAfterCount`];
  const showTimer = behaviorAfterCount === 'repeat_counter' && attributes.scheduleDates.start >= new Date().valueOf() && behavior.countDirection == 'down';

  const { days, hours, minutes, seconds } = timeProps;
  const values = showTimer ? {days: 0, hours: 0, minutes: 0, seconds: 0}: { days, hours, minutes, seconds };
  const activeTimePeriods = TIME_PERIODS.filter(period => attributes[`show${period}`]).map(period =>
    period.toLowerCase()
  );
  const updateFrequency = INTERVAL_UNIT_MS[attributes.intervalUnit] * attributes.interval;
  const createDateElements = ({ activeTimePeriods, values }) =>
    activeTimePeriods.map(period => (
      <Counter key={period} inMobileOrTabletPreview={inMobileOrTabletPreview(attributes, meta)}>
        <CounterText>{attributes[`${period}Label`]}: </CounterText>
        <CounterValues>{zeroPad(values[period])}</CounterValues>
      </Counter>
    ));

  const parentWebComponent = () => {
    const elements = document.getElementsByTagName('web-component-embed');
    const elementsArray = Array.from(elements);
    const filteredElement = elementsArray.find(element => {
      return element.getAttribute('id') === meta.hashid;
    });

    return filteredElement;
  }  

  useEffect(() => {
    if (meta.is_standalone || meta.external_id_type !== 'shopify') return;

    const container = shadowRoot.querySelector(`#fixed-header-container`);
    if (parentWebComponent() && container) {
      parentWebComponent().style.marginTop = `${container.offsetHeight}px`;
    }
  }, []);

  const closeTimer = () => {
    if (meta.is_standalone) return;
    if (parentWebComponent()) parentWebComponent().style.display = 'none';
    shadowRoot.querySelector(`#${POWR_SSR_ID}`).style.display = 'none';
  };

  const elements =
    behavior.type === 'date'
      ? createDateElements({ activeTimePeriods, values })
      : createNumberElements({ value: Math.abs(timeProps.total), updateFrequency });
  let container = (
    <Container>
      <Cross>
        {/*https://fontawesome.com/icons/xmark?s=regular&f=classic*/}
        <svg onClick={closeTimer} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z" />
        </svg>
      </Cross>
      <FixedHeaderTimer id="timer">
        <TextBeforeWithIcon id="emoji-with-text">
          <Icon>{EMOJIS[attributes.fixedHeader_textBeforeCountEmoji]}</Icon>
          <CounterText>{attributes.fixedHeader_textBeforeCount}</CounterText>
        </TextBeforeWithIcon>
        <CounterContainer id="elements">
          {elements}
          {UpgradeHelper.isUpgradeRequired(meta.premium_status, 'premium', meta, globals) && (
            <CountdownPowrMark powrUrl={powrUrl} margin="unset" height="20px" width="15px" />
          )}
        </CounterContainer>
          <Condition match={attributes.buttonType === 'link'}>
            <ButtonLinkFixedHeader target={attributes.buttonLinkBehavior} href={attributes.buttonLink}>
              {attributes.fixedHeaderButtonText}
            </ButtonLinkFixedHeader>
          </Condition>
      </FixedHeaderTimer>
    </Container>
  );
  if (attributes.buttonType === 'clickableBar') {
      container = (
          <a href={attributes.buttonLink} target={attributes.buttonLinkBehavior}>
              {container}
          </a>
      );
  }
  return container;
}
