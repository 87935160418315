import { useContext } from 'react';
import Condition from '../../../../../modules/react_components/common/condition';
import CountdownPowrMark from '../../CountdownPowrMark/CountdownPowrMark';
import UpgradeHelper from '../../../../../helpers/upgrade_helper';

import { zeroPad } from 'react-countdown';
import { bounceInUp, fadeOut, fadeOutUp, flip, rotateOut, rubberBand, zoomIn, zoomOut } from 'react-animations';
import {
  INTERVAL_UNIT_MS,
  MOBILE_SCREEN_COEFFICIENT,
  multiplySize,
  TIME_PERIODS,
  TIMER_GOAL_BEHAVIOR,
  minSize
} from '../../utils';
import { ButtonLayout } from '../../ButtonLayout/ButtonLayout';
import { BannerSubtitle } from '../../BannerSubtitle/BannerSubtitle';
import { ButtonLink } from '../../ButtonLink/ButtonLink';
import { BannerTitle } from '../../BannerTitle/BannerTitle';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import AppContext from '../../../../../context/AppContext';
import { classNameTimerGrid, varsTimerGrid } from './TimerGrid.css.ts';
import { classNameTimerGridCell } from './TimerGridCell.css.ts';
import { classNamesWatermarkWrapper, varsWatermarkWrapper } from './WatermarkWrapper.css.ts';
import { classNamesAnimation, classNamesCounterNumber, varsCounterNumber } from './CounterNumber.css.ts';
import { classNameLabel, varsLabel } from './Label.css.ts';
import { classNameSeparator } from './Separator.css.ts';
import { classNameLayout, varsLayout } from './Layout.css.ts';
import { classNameCounterDefault, varsCounterDefault } from './CounterDefault.css.ts';
import { MOBILE_MAX_FONT_SIZE } from '../../../../../helpers/constants';

const ANIMATIONS = {
  zoomIn,
  flip,
  rotateOut,
  fadeOut,
  zoomOut,
  rubberBand,
  fadeOutUp,
  bounceInUp,
};

const WatermarkWrapper = function ({ children }) {
  const { attributes } = useContext(AppContext);

  const behavior = TIMER_GOAL_BEHAVIOR[attributes.timerGoal];

  const joinedNumbers = behavior.type === 'number' && attributes.counterStyle === 'joined';
  const dontShowBackground = attributes.counterFigure === 'no_borders' || joinedNumbers;

  const padding = dontShowBackground ? '10%' : `15%`;
  const borderRadius = attributes.counterFigure === 'cycle' ? '50%' : 'unset';

  let className = `${classNamesWatermarkWrapper.default} custom-css-mark-wrapper-default`;
  let style = assignInlineVars(varsWatermarkWrapper, {
    padding,
    borderRadius,
    fontSize: {
      v1: minSize(MOBILE_MAX_FONT_SIZE, attributes.banner_counterFontSize),
      v2: attributes.banner_counterFontSize,
    },
  });

  if (
    attributes.counterFigure !== 'no_borders' &&
    (attributes.counterStyle === 'separate' || behavior.type === 'date')
  ) {
    className = `${classNamesWatermarkWrapper.one} custom-css-mark-wrapper-one`;
  }

  if (attributes.counterFigure === 'cycle') {
    className = `${classNamesWatermarkWrapper.two} custom-css-mark-wrapper-two`;
  }

  return (
    <span className={className} style={style}>
      {children}
    </span>
  );
};

const powrmark = powrUrl => {
  return (
    <WatermarkWrapper id="watermark" key={2}>
      <CountdownPowrMark powrUrl={powrUrl} margin="unset" />
    </WatermarkWrapper>
  );
};

const createDateElements = ({ periods, prevValue, values, separator, premiumStatus, powrUrl, meta, globals }) => {
  const createTimePeriodContainer = period => {
    const numbers = [];
    const prevTimeString = zeroPad(prevValue[period]);
    const currentTimeString = values[period];
    let changed = false;
    [0, 1, 2, 3].forEach(index => {
      if (prevTimeString[index] || currentTimeString[index]) {
        if (prevTimeString[index] !== currentTimeString[index]) {
          numbers.push(
            <CounterNumber key={index} className="counterNumber">
              <Animated>{currentTimeString[index]}</Animated>
            </CounterNumber>
          );
          changed = true;
        } else {
          numbers.push(
            <CounterNumber key={index} className="counterNumber">
              {currentTimeString[index]}
            </CounterNumber>
          );
        }
      }
    });
    if (changed) {
      prevValue[period] = values[period];
    }
    return (
      <Counter className="counter" key={period}>
        {numbers}
      </Counter>
    );
  };

  const timePeriodContainers = periods.map(createTimePeriodContainer);
  const timeWithSeparators = timePeriodContainers.reduce((prev, curr) => [prev, separator, curr]);
  if (UpgradeHelper.isUpgradeRequired(premiumStatus, 'premium', meta, globals)) timeWithSeparators.push(powrmark(powrUrl)); // extra Counter with powr watermark
  return timeWithSeparators;
};

// turns 123 number into 00123 countdown/up
// and 1233456 into 0123456 - adds one leading zero
/* eslint-disable react/prop-types */
const createNumberElements = ({ value, prevValue, updateFrequency, premiumStatus, powrUrl, meta, globals }) => {
  const numberToShow = parseInt(value / updateFrequency);
  const maxElementsAmount = numberToShow.toString().length + 1;
  const numAsString = zeroPad(numberToShow, Math.max(5, maxElementsAmount));
  const prevNumAsString = zeroPad(prevValue.number, Math.max(5, maxElementsAmount));

  if (numberToShow !== prevValue.number) {
    prevValue.number = numberToShow;
  }
  const containersWithNumbers = [];
  for (let index = 0; index < numAsString.length; index++) {
    if (numAsString[index] !== prevNumAsString[index]) {
      containersWithNumbers.push(
        <Counter className="counter">
          <CounterNumber className="counterNumber">
            <Animated>{numAsString[index]}</Animated>
          </CounterNumber>
        </Counter>
      );
    } else {
      containersWithNumbers.push(
        <Counter className="counter">
          <CounterNumber className="counterNumber">{numAsString[index]}</CounterNumber>
        </Counter>
      );
    }
  }
  if (UpgradeHelper.isUpgradeRequired(premiumStatus, 'premium', meta, globals.CURRENT_USER)) containersWithNumbers.push(powrmark(powrUrl)); // extra Counter with powr watermark
  return containersWithNumbers;
};

const TimerGrid = function ({ children, itemsCount }) {
  const { attributes } = useContext(AppContext);

  const behavior = TIMER_GOAL_BEHAVIOR[attributes.timerGoal];

  let borderRadius = 'unset';
  let border = 'unset';
  let padding = 'unset';
  let backgroundColor = 'unset';
  let backgroundImage = 'unset';
  if (behavior.type === 'number' && attributes.counterStyle === 'joined' && attributes.counterFigure !== 'no_borders') {
    [backgroundColor, backgroundImage] = getBackground(attributes);
    borderRadius = attributes.counterFigure === 'cycle' ? '50%' : attributes.banner_counterBorderRadius;
    border = `${attributes.banner_counterBorderSize} solid ${attributes.banner_counterBorderColor}`;
    padding = '2%';
  }

  return (
    <div
      className={`${classNameTimerGrid} custom-css-timer-grid`}
      style={assignInlineVars(varsTimerGrid, {
        gridTemplateColumns: `repeat(${itemsCount}, auto)`,
        gap: {
          v1: `${attributes.banner_labelSpacing} ${minSize(attributes.elementSpacing, '3px')}`,
          v2: `${attributes.banner_labelSpacing} ${attributes.elementSpacing}`,
        },
        borderRadius,
        border,
        padding,
        backgroundColor,
        backgroundImage,
      })}
    >
      {children}
    </div>
  );
};

const TimerGridCell = function ({ children }) {
  return <div className={`${classNameTimerGridCell} custom-css-grid-cell`}>{children}</div>;
};

const getBackground = attributes => {
  return [
    attributes.banner_counterBackgroundGradient ? `unset` : attributes.banner_counterBackgroundColor,
    attributes.banner_counterBackgroundGradient
      ? `linear-gradient(${attributes.banner_counterBackgroundStartColor}, ${attributes.banner_counterBackgroundEndColor})`
      : 'unset',
  ];
};

const CounterDefault = function ({ style, children }) {
  const { attributes } = useContext(AppContext);

  const behavior = TIMER_GOAL_BEHAVIOR[attributes.timerGoal];

  let [backgroundColor, backgroundImage] = getBackground(attributes);
  const joinedNumbers = behavior.type === 'number' && attributes.counterStyle === 'joined';
  if (attributes.counterStyle === 'separate' || joinedNumbers) {
    backgroundColor = 'unset';
    backgroundImage = 'unset';
  }

  return (
    <span
      className={classNameCounterDefault}
      style={{
        ...assignInlineVars(varsCounterDefault, {
          color: attributes.banner_counterFontColor,
          fontFamily: attributes.banner_counterFontFamily,
          fontWeight: attributes.banner_counterFontStyle,
          fontStyle: attributes.banner_counterFontStyle,
          backgroundColor,
          backgroundImage,
          fontSize: {
            v1: minSize(MOBILE_MAX_FONT_SIZE, attributes.banner_counterFontSize),
            v2: attributes.banner_counterFontSize,
          },
        }),
        ...style,
      }}
    >
      {children}
    </span>
  );
};

const Counter = function ({ children }) {
  const { attributes } = useContext(AppContext);

  const behavior = TIMER_GOAL_BEHAVIOR[attributes.timerGoal];

  if (attributes.counterStyle === 'separate') {
    return (
      <CounterDefault
        style={{
          width: 'unset',
          height: 'unset',
          gap: attributes.elementSpacing,
        }}
      >
        {children}
      </CounterDefault>
    );
  }
  if (behavior.type === 'number') {
    return (
      <CounterDefault
        style={{
          width: 'unset',
          height: 'unset',
        }}
      >
        {children}
      </CounterDefault>
    );
  }
  if (attributes.counterFigure === 'no_borders') {
    return (
      <CounterDefault
        style={{
          border: 'none',
        }}
      >
        {children}
      </CounterDefault>
    );
  }

  return (
    <CounterDefault
      style={{
        border: `${attributes.banner_counterBorderSize} solid ${attributes.banner_counterBorderColor}`,
        overflow: 'hidden',
        borderRadius: attributes.counterFigure === 'square' ? attributes.banner_counterBorderRadius : '50%',
      }}
    >
      {children}
    </CounterDefault>
  );
};

const CounterNumber = function ({ children }) {
  const { attributes } = useContext(AppContext);

  const isServer = typeof window === 'undefined';

  const wrappedChildren = isServer ? <span style={{ visibility: 'hidden' }}>{children}</span> : children;

  if (attributes.counterFigure === 'no_borders') {
    return (
      <span
        style={{
          border: 'none',
          backgroundColor: 'unset',
          backgroundImage: 'unset',
        }}
      >
        {wrappedChildren}
      </span>
    );
  }

  if (attributes.counterStyle === 'separate') {
    let [backgroundColor, backgroundImage] = getBackground(attributes);
    let defaults = assignInlineVars(varsCounterNumber, {
      display: 'flex',
      backgroundColor,
      backgroundImage,
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: {
        v1: minSize(MOBILE_MAX_FONT_SIZE, attributes.banner_counterFontSize),
        v2: attributes.banner_counterFontSize,
      },
    });

    if (attributes.counterFigure === 'square') {
      let extra = assignInlineVars(varsCounterNumber, {
        gap: attributes.elementSpacing,
        border: `${attributes.banner_counterBorderSize} solid ${attributes.banner_counterBorderColor}`,
        borderRadius: attributes.banner_counterBorderRadius,
        fontSize: {
          v1: minSize(MOBILE_MAX_FONT_SIZE, attributes.banner_counterFontSize),
          v2: attributes.banner_counterFontSize,
        },
      });
      return (
        <span className={`${classNamesCounterNumber.square} custom-css-count-number-square`} style={{ ...defaults, ...extra }}>
          {wrappedChildren}
        </span>
      );
    } else if (attributes.counterFigure === 'cycle') {
      let extra = assignInlineVars(varsCounterNumber, {
        gap: attributes.elementSpacing,
        borderRadius: '50%',
        overflow: 'hidden',
        border: `${attributes.banner_counterBorderSize} solid ${attributes.banner_counterBorderColor}`,
      });
      return (
        <span className={`${classNamesCounterNumber.cycle} custom-css-count-number-cycle`} style={{ ...defaults, ...extra }}>
          {wrappedChildren}
        </span>
      );
    } else if (attributes.counterFigure === 'no_borders') {
      let extra = assignInlineVars(varsCounterNumber, {
        height: 'unset',
        fontSize: {
          v1: minSize(MOBILE_MAX_FONT_SIZE, attributes.banner_counterFontSize),
          v2: attributes.banner_counterFontSize,
        },
      });
      return (
        <span className={`${classNamesCounterNumber.no_borders} custom-css-count-number-no-borders`} style={{ ...defaults, ...extra }}>
          {wrappedChildren}
        </span>
      );
    } else {
      return (
        <span className={`${classNamesCounterNumber.switchDefault} custom-css-count-number-default`} style={defaults}>
          {wrappedChildren}
        </span>
      );
    }
  }

  return <span style={{ display: 'flex' }}>{wrappedChildren}</span>;
};

const Animated = function ({ children }) {
  const { attributes } = useContext(AppContext);

  return <div className={`${classNamesAnimation[attributes.countdownAnimation]} custom-css-animation`}>{children}</div>;
};

const Label = function ({ children }) {
  const { attributes } = useContext(AppContext);
  return (
    <span
      className={`${classNameLabel} custom-css-label`}
      style={assignInlineVars(varsLabel, {
        color: attributes.banner_labelFontColor,
        fontFamily: attributes.banner_labelFontFamily,
        fontSize: {
          v1: minSize(attributes.banner_labelFontSize, 20),
          v2: attributes.banner_labelFontSize,
        },
        fontStyle: attributes.banner_labelFontStyle,
        fontWeight: attributes.banner_labelFontStyle,
      })}
    >
      {children}
    </span>
  );
};

const Separator = function ({ children }) {
  return <span className={`${classNameSeparator} custom-css-separator`}>{children}</span>;
};

const Layout = function ({ children }) {
  const { attributes } = useContext(AppContext);

  return (
    <div
      className={`${classNameLayout} custom-css-bg-layout oembed-iframe-size`}
      style={assignInlineVars(varsLayout, {
        backgroundColor: attributes.banner_backgroundGradient ? `unset` : attributes.banner_backgroundColor,
        backgroundImage: attributes.banner_backgroundGradient
          ? `linear-gradient(${attributes.banner_backgroundStartColor}, ${attributes.banner_backgroundEndColor})`
          : 'unset',
        padding: attributes.countdownType === 'banner' ? `${attributes.verticalPadding} ${attributes.horizontalPadding}` : 'unset'
      })}
    >
      {children}
    </div>
  );
};

const separators = {
  none: '',
  colon: ':',
  period: '.',
  dash: '-',
};

/* eslint-disable react/prop-types */
export default function Banner(timeProps) {
  const { attributes, meta, params, globals } = useContext(AppContext);
  const behaviorAfterCount = attributes[`${attributes.countdownType}_behaviorAfterCount`];

  const behavior = TIMER_GOAL_BEHAVIOR[attributes.timerGoal];

  const activeTimePeriods = TIME_PERIODS.filter(period => attributes[`show${period}`]).map(period =>
    period.toLowerCase()
  );
  const separator = (
    // 0 is random number just to remove React warning
    <TimerGridCell key={0}>
      <Separator>{separators[attributes.separatorStyle]}</Separator>
    </TimerGridCell>
  );

  const renderButtonLink = () => (
    <ButtonLayout>
      <ButtonLink target={attributes.buttonLinkBehavior} href={attributes.buttonLink}>
        {attributes.buttonText}
      </ButtonLink>
    </ButtonLayout>
  );

  const updateFrequency = INTERVAL_UNIT_MS[attributes.intervalUnit] * attributes.interval;
  const value = { days: '00', hours: '00', minutes: '00', seconds: '00' };
  const showTimer = behaviorAfterCount === 'repeat_counter' && attributes.scheduleDates.start >= new Date().valueOf() && behavior.countDirection == 'down';
  const elements =
    behavior.type === 'date'
      ? createDateElements({
          periods: activeTimePeriods,
          prevValue: timeProps.prevValue,
          values: showTimer ? value : timeProps.formatted,
          separator,
          premiumStatus: meta.premium_status,
          powrUrl: params.powrUrl,
          meta,
          globals
        })
      : createNumberElements({
          prevValue: timeProps.prevValue,
          value: Math.abs(timeProps.total),
          updateFrequency,
          premiumStatus: meta.premium_status,
          powrUrl: params.powrUrl,
          meta,
          globals
        });

  const labels =
    behavior.type === 'date'
      ? activeTimePeriods
          .map(period => (
            <TimerGridCell key={period}>
              <Label>{attributes[`${period}Label`]}</Label>
            </TimerGridCell>
          ))
          .reduce((prev, curr, index) => [prev, <TimerGridCell key={index} />, curr])
      : null;

  let itemsCount;
  if (behavior.type === 'date') {
    itemsCount = TIME_PERIODS.filter(period => attributes[`show${period}`]).length * 2 - 1;
  } else {
    const numberToShow = parseInt(Math.abs(timeProps.total) / updateFrequency);
    const maxElementsAmount = numberToShow.toString().length + 1;
    itemsCount = Math.max(maxElementsAmount, 5);
  }

  if (UpgradeHelper.isUpgradeRequired(meta.premium_status, 'premium', meta, globals)) {
    itemsCount += 1; // extra Counter with powr watermark
    if (labels !== null) {
      labels.push(<TimerGridCell key={itemsCount} />);
    }
  }
  return (
    <Layout>
      <div style={{ textAlign: attributes.banner_titleAndSubtitleAlignments, width: '100%', margin: '10px 10px 0px 10px' }}>
        <Condition match={attributes.banner_beforeTitle !== null}>
          <BannerTitle id="banner_beforeTitle">{attributes.banner_beforeTitle}</BannerTitle>
        </Condition>
        <Condition match={attributes.banner_beforeSubtitle !== null}>
          <BannerSubtitle>{attributes.banner_beforeSubtitle}</BannerSubtitle>
        </Condition>
        <Condition match={attributes.buttonPosition === 'top' && attributes.buttonType === 'link'}>
          {renderButtonLink()}
        </Condition>
      </div>
      <TimerGrid itemsCount={itemsCount} id="timerGrid">
        {elements}
        {labels}
      </TimerGrid>
      <Condition match={attributes.buttonPosition === 'bottom' && attributes.buttonType === 'link'}>
        {renderButtonLink()}
      </Condition>
    </Layout>
  );
}
