import PropTypes from 'prop-types';
import PowrLogo from '../../../../modules/react_components/powr_logo';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { classNamePowrMark, varsPowrMark } from './CountdownPowrMark.css.ts';

const CountdownPowrMark = props => {
  const powrUrl = props.powrUrl;
  const href = `${powrUrl}/?src=watermark_countdownTimer`;
  const onClick = null;
  const fullWidthHeight = { width: '100%', height: '100%' };
  return (
    <span
      className={`${classNamePowrMark} custom-css-powr-mark`}
      style={assignInlineVars(varsPowrMark, {
        margin: props.margin || '0 5px 0 14px',
        height: props.height || '100%',
        width: props.width || 'unset',
      })}
    >
      <a href={href} onClick={onClick} target="_blank" rel="noopener noreferrer">
        <PowrLogo small svgStyle={fullWidthHeight} wrapperStyle={fullWidthHeight} />
      </a>
    </span>
  );
};

CountdownPowrMark.propTypes = {
  small: PropTypes.bool,
  premium_status: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
};

export default CountdownPowrMark;
