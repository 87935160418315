import './powrMark.css';
import { useContext } from 'react';
import AppContext from '../../context/AppContext';
var PowrMark = function (props) {
    var _a = useContext(AppContext), _b = _a.meta, app_slug = _b.app_slug, app_type = _b.app_type, is_standalone = _b.is_standalone, powrUrl = _a.params.powrUrl;
    var href = "".concat(powrUrl, "?src=watermark_").concat(app_type);
    var handleLinkClick = function (e) {
        if (is_standalone && window.handleTriggerPremiumClick) {
            e.preventDefault();
            // get this function from powr
            window.handleTriggerPremiumClick();
        }
    };
    return (<div className="powr-mark">
      <a className="powr-mark-link" href={href} target="_blank" rel="noreferrer" onClick={handleLinkClick}>
        <img alt="POWR logo" className="powr-mark-logo" src="https://www.powrcdn.com/images/svgs/logo/powr-logo.svg"/>
        <span className="powr-mark-text">
          <u className="powr-mark-slug">{app_slug}</u> - Create your own for free!
        </span>
      </a>
    </div>);
};
export default PowrMark;
