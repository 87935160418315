import { useContext } from 'react';
import { EMOJIS } from '../../utils';
import CountdownPowrMark from '../../CountdownPowrMark/CountdownPowrMark';
import UpgradeHelper from '../../../../../helpers/upgrade_helper';
import {
  classNameCartTimer,
  classNameContainer,
  classNameIcon,
  classNameText,
  classNameTimer,
  varsCartTimer,
} from './Cart.css.ts';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import AppContext from '../../../../../context/AppContext';

const TIME_PERIODS = {
  s: ['seconds'],
  m: ['minutes', 'seconds'],
  h: ['hours', 'minutes', 'seconds'],
  d: ['days', 'hours', 'minutes', 'seconds'],
};

export const Container = function ({ children }) {
  return <div className={`${classNameContainer} custom-css-cart-container oembed-iframe-size`}>{children}</div>;
};

export const CartTimer = function ({ children }) {
  const { attributes } = useContext(AppContext);

  return (
    <div
      className={`${classNameCartTimer} custom-css-cart-timer`}
      style={assignInlineVars(varsCartTimer, {
        backgroundColor: attributes.cart_backgroundGradient ? `unset` : attributes.cart_backgroundColor,
        color: attributes.cart_generalTextFontColor,
        fontFamily: attributes.cart_generalTextFontFamily,
        fontSize: '18px',
        fontStyle: attributes.cart_generalTextFontStyle,
        fontWeight: attributes.cart_generalTextFontStyle,
        textAlign: 'center',
        backgroundImage: attributes.cart_backgroundGradient
          ? `linear-gradient(${attributes.cart_backgroundStartColor}, ${attributes.cart_backgroundEndColor})`
          : 'unset',
      })}
    >
      {children}
    </div>
  );
};

export const Timer = function ({ children }) {
  return <span className={`${classNameTimer} custom-css-cart-timer`}>{children}</span>;
};

export const Icon = function ({ children }) {
  return <span className={`${classNameIcon} custom-css-cart-icon`}>{children}</span>;
};

export const Text = function ({ children }) {
  return <span className={`${classNameText} custom-css-cart-text`}>{children}</span>;
};

const Counter = ({ children }) => {
  const { attributes } = useContext(AppContext);
  return (
    <span
      style={{
        backgroundColor: attributes.cart_counterBackgroundGradient ? 'unset' : attributes.cart_counterBackgroundColor,
        backgroundImage: attributes.cart_counterBackgroundGradient
          ? `linear-gradient(${attributes.cart_counterBackgroundStartColor}, ${attributes.cart_counterBackgroundEndColor})`
          : 'unset',
        border: `${attributes.cart_counterBorderSize} solid ${attributes.cart_counterBorderColor}`,
        borderRadius: attributes.cart_counterBorderRadius,
        fontSize: '18px',
        fontWeight: 800,
      }}
    >
      {children}
    </span>
  );
};

/* eslint-disable react/prop-types */
export default function Cart(timeProps) {
  const {
    attributes,
    meta,
    params: { powrUrl },
    globals
  } = useContext(AppContext);
  const activeTimePeriods = TIME_PERIODS[attributes.perVisitorUnit];
  const renderTimer = activeTimePeriods.map(period => <Counter key={period}>{timeProps.formatted[period]}</Counter>);
  const timerWithSeparators = () => renderTimer.reduce((prev, curr, index) => [prev, <Text key={index}>:</Text>, curr]);

  return (
    <Container>
      <CartTimer>
        <Icon>{EMOJIS[attributes.cart_textBeforeCountEmoji]} </Icon>
        <Text>{attributes.cart_textBeforeCount} </Text>
        <Timer>{timerWithSeparators()}</Timer>
        {UpgradeHelper.isUpgradeRequired(meta.premium_status, 'premium', meta, globals) && (
          <CountdownPowrMark powrUrl={powrUrl} height="unset" />
        )}
        <Text> {attributes.cart_textAfterCount}</Text>
      </CartTimer>
    </Container>
  );
}
