import { EditButton } from './EditButton/EditButton';
import { TrialEndOverlay } from './TrialEndedOverlay/TrialEndOverlay';
import './GlobalStyles.css.ts';
import AppContext from '../context/AppContext';
import PowrMark from '../components/PowrMark/PowrMark';
import { lazy, Suspense } from 'react';
import UpgradeHelper from '../helpers/upgrade_helper';
import { BOTTOM_WATERMARK_EXCEPTIONS, APPS_WITHOUT_IFRAME } from '../helpers/constants';
import { kebabize, isWix, isRailsViewPage } from '../helpers/webcomponentHelpers';
import { isEmbedded } from '../helpers/hydrateHelpers';

const LastViewedUrl = lazy(() => import('../components/LastViewedUrl'));

const isVisible = meta => {
  return meta && !meta.is_standalone && meta.edit_me && !isWix(meta) && !isRailsViewPage();
};

const Plugin = ({ attributes, meta, params, globals, shadowRoot, Component }) => {
  const context = {
    attributes,
    meta,
    params,
    globals,
    shadowRoot,
  };
  if (['wix-local', 'wix-staging'].includes(meta.app_slug)) {
    meta.app_slug = kebabize(meta.app_namespace); // in case of local or staging
  }
  const showPowrMark =
    !BOTTOM_WATERMARK_EXCEPTIONS.includes(meta.app_slug) &&
    UpgradeHelper.isUpgradeRequired(meta.premium_status, 'premium', meta, globals);
  const powrOneAppTrialEndedOverlay = meta.powr_one_app_trial_ended_overlay;
  return (
    <AppContext.Provider value={context}>
      {isVisible(meta) && <EditButton></EditButton>}
      {powrOneAppTrialEndedOverlay && <TrialEndOverlay />}
      <Component></Component>
      {showPowrMark && <PowrMark powrUrl={params.powrUrl} appSlug={meta.app_slug} />}
      {/* Works only for the webcomponents that can work without parent iframe*/}
      {APPS_WITHOUT_IFRAME.includes(meta.app_slug) && isEmbedded(shadowRoot) && (
        <Suspense>
          <LastViewedUrl powrUrl={params.powrUrl} appSlug={meta.app_slug} appId={meta.id} />
        </Suspense>
      )}
      {typeof window !== 'undefined' && window.Wix &&
        <Suspense>
          <LastViewedUrl 
            powrUrl={params.powrUrl} 
            appSlug={meta.app_slug} 
            appId={meta.id}
            isWix={true} 
          />
        </Suspense>
      }
    </AppContext.Provider>
  );
};

export default Plugin;
