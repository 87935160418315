import { useContext } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { classNameButtonLink, varsButtonLink } from './ButtonLinkFixedHeader.css.ts';
import AppContext from '../../../../context/AppContext';
import {multiplySize} from "../utils";

export function ButtonLinkFixedHeader(props) {
  const { attributes } = useContext(AppContext);
  return (
    <a
      href={props.href}
      target={props.target}
      className={`${classNameButtonLink} custom-css-btn-header-link`}
      style={assignInlineVars(varsButtonLink, {
        backgroundColor: attributes.fixedHeader_buttonColor,
        color: attributes.fixedHeader_buttonFontColor,
        fontFamily: attributes.fixedHeader_buttonFontFamily,
        fontSize: attributes.fixedHeader_buttonFontSize,
        fontStyle: attributes.fixedHeader_buttonFontStyle,
        fontWeight: attributes.fixedHeader_buttonFontWeight,
        padding: `${attributes.fixedHeader_buttonSize} ${multiplySize(attributes.fixedHeader_buttonSize, 2)}`,
        borderRadius: attributes.borderRadius,
        ':link': {
          color: `${attributes.fixedHeader_buttonFontColor} !important`,
        },
      })}
    >
      {props.children}
    </a>
  );
}
