export const FREE_PLANS = ['free', 'v_250'];

export const SHOW_BAC_TERMS = [
  'display_message',
  'empty_cart_display_message',
  'empty_cart_hide_counter',
  'hide_counter',
];

export const EMOJIS = {
  emoji_empty: '',
  emoji_not_sign: '🚫',
  emoji_fire: '🔥',
  emoji_santa: '🎅',
  emoji_point_right: '👉',
  emoji_good: '👍',
  emoji_rocket: '🚀',
  emoji_heart: '❤️',
  emoji_smile: '😀',
  emoji_time: '⏰',
  emoji_party_popper: '🎉',
};

export const LABEL_VALUE_EMOJIS = Object.keys(EMOJIS).map(emojis_key => {
  return {
    emoji: EMOJIS[emojis_key],
    value: emojis_key,
  };
});

export const multiplySize = (sizeInPX, quantity) => {
  return parseInt(sizeInPX) * quantity + 'px';
};

export const INTERVAL_UNIT_MS = {
  ms: 1,
  s: 1000,
  m: 60 * 1000,
  h: 3600 * 1000,
  d: 24 * 3600 * 1000,
};

export const MOBILE_SCREEN_COEFFICIENT = 0.4;

export const TIMER_GOAL_BEHAVIOR = {
  countdownDate: {
    type: 'date',
    countDirection: 'down',
    propsChanges: () => ({
      showYears: true,
      showDays: true,
      showHours: true,
      showMinutes: true,
      showSeconds: true,
      activeDates: 'choosePeriod',
    }),
    rangeSelector: 'choose-period',
    fixedHeader: {
      fixedHeader_textBeforeCount: 'Get 30% off. Sale ends in:',
      fixedHeader_textBeforeCountEmoji: 'emoji_fire',
    },
    banner: {
      banner_beforeTitle: 'WEBSITE COMING SOON!',
      banner_beforeTitleFontColor: 'rgba(66,66,66,1)',
      banner_counterBackgroundColor: '#ff4632',
      banner_beforeTitleFontFamily: 'Nunito',
      banner_beforeTitleFontSize: '36px',
      banner_beforeTitleFontStyle: 'bold',
      banner_beforeTitleSpacing: '10px',
      banner_borderColor: 'rgba(248, 246, 246)',
      banner_counterBorderSize: '0px',
      counterFigure: 'square',
      banner_counterFontColor: 'white',
      counterStyle: 'separate',
    },
  },
  countdownTime: {
    type: 'date',
    countDirection: 'down',
    // made it a function because of moment() calls in the code
    propsChanges: () => ({
      showYears: false,
      showDays: false,
      showHours: true,
      showMinutes: true,
      showSeconds: true,
      activeDates: 'choosePeriod',
      scheduleDates: {
        start: moment().valueOf(),
        end: moment().add(20, 'hour').valueOf(),
      },
    }),
    rangeSelector: 'choose-period',
    fixedHeader: {
      fixedHeader_textBeforeCount: 'Event starts in:',
      fixedHeader_textBeforeCountEmoji: 'emoji_rocket',
    },
    banner: {
      banner_beforeTitle: 'WEBSITE COMING SOON!',
      banner_beforeTitleFontColor: 'rgba(66,66,66,1)',
      banner_counterBackgroundColor: 'white',
      banner_beforeTitleFontFamily: 'Nunito',
      banner_beforeTitleFontSize: '36px',
      banner_beforeTitleFontStyle: 'bold',
      banner_borderColor: 'rgba(248, 246, 246)',
      banner_counterBorderSize: '0px',
      banner_counterFontColor: '#ff4632',
      counterFigure: 'no_borders',
    },
  },
  countdownTimeVisitor: {
    type: 'date',
    countDirection: 'down',
    useCookie: true,
    rangeSelector: 'always-active|choose-period|time-interval-per-visitor',
    propsChanges: () => ({
      showYears: false,
      showDays: false,
    }),
    fixedHeader: {
      fixedHeader_textBeforeCount: 'Limited Time Offer!',
      fixedHeader_textBeforeCountEmoji: 'emoji_fire',
    },
    banner: {
      banner_beforeSubtitle: '',
      banner_beforeTitle: 'Get 30% off. Sale ends in:',
      banner_counterBackgroundColor: '#ff4632',
      banner_beforeTitleFontColor: 'rgba(66,66,66,1)',
      banner_beforeTitleFontFamily: 'Nunito',
      banner_beforeTitleFontSize: '36px',
      banner_beforeTitleFontStyle: 'bold',
      banner_beforeTitleSpacing: '10px',
      banner_borderColor: 'rgba(248, 246, 246)',
      banner_borderRadius: '0px',
      banner_counterBorderSize: '0px',
      counterFigure: 'square',
      banner_counterFontColor: 'white',
      counterStyle: 'separate',
    },
  },
  countdownNumberVisitor: {
    type: 'number',
    countDirection: 'down',
    useCookie: true,
    rangeSelector: 'always-active|choose-period|starting-number|time-frequency',
    fixedHeader: {
      fixedHeader_textBeforeCount: 'Only a Few Left!',
      fixedHeader_textBeforeCountEmoji: 'emoji_time',
    },
    banner: {
      banner_beforeSubtitle: '',
      banner_beforeTitle: 'Only a Few Left!',
      banner_beforeTitleFontColor: 'rgba(66,66,66,1)',
      banner_beforeTitleFontFamily: 'Nunito',
      banner_beforeTitleFontSize: '36px',
      banner_beforeTitleFontStyle: 'bold',
      banner_beforeTitleSpacing: '10px',
      banner_borderColor: 'rgba(248, 246, 246)',
      banner_borderRadius: '0px',
      banner_counterBorderSize: '0px',
      counterFigure: 'square',
      banner_counterFontColor: 'white',
      counterStyle: 'separate',
      banner_counterBackgroundColor: '#ff4632',
    },
  },
  countupNumber: {
    type: 'number',
    countDirection: 'up',
    rangeSelector: 'always-active|choose-period|starting-number|time-frequency',
    fixedHeader: {
      fixedHeader_textBeforeCount: 'Used by Hundreds!',
      fixedHeader_textBeforeCountEmoji: 'emoji_heart',
    },
    banner: {
      banner_beforeSubtitle: '',
      banner_beforeTitle: 'Used by Hundreds!',
      banner_beforeTitleFontColor: 'rgba(66,66,66,1)',
      banner_beforeTitleFontFamily: 'Nunito',
      banner_beforeTitleFontSize: '36px',
      banner_beforeTitleFontStyle: 'bold',
      banner_beforeTitleSpacing: '10px',
      banner_borderColor: 'rgba(248, 246, 246)',
      banner_borderRadius: '0px',
      banner_counterBorderSize: '0px',
      counterFigure: 'square',
      banner_counterFontColor: 'white',
      counterStyle: 'separate',
      banner_counterBackgroundColor: '#ff4632',
    },
  },
  countupNumberVisitor: {
    type: 'number',
    countDirection: 'up',
    useCookie: true,
    rangeSelector: 'always-active|choose-period|starting-number|time-frequency',
    fixedHeader: {
      fixedHeader_textBeforeCount: 'Sold in the Last Hour!',
      fixedHeader_textBeforeCountEmoji: 'emoji_party_popper',
    },
    banner: {
      banner_beforeSubtitle: '',
      banner_beforeTitle: 'Sold in the Last Hour!',
      banner_beforeTitleFontColor: 'rgba(66,66,66,1)',
      banner_beforeTitleFontFamily: 'Nunito',
      banner_beforeTitleFontSize: '36px',
      banner_beforeTitleFontStyle: 'bold',
      banner_beforeTitleSpacing: '10px',
      banner_borderColor: 'rgba(248, 246, 246)',
      banner_borderRadius: '0px',
      banner_counterBorderSize: '0px',
      counterFigure: 'square',
      banner_counterFontColor: 'white',
      counterStyle: 'separate',
      banner_counterBackgroundColor: '#ff4632',
    },
  },
  countupDate: {
    type: 'date',
    countDirection: 'up',
    rangeSelector: 'always-active|choose-period|start-date',
    propsChanges: () => ({
      startDate: Date.now() - 10000000,
      showDays: true,
      showHours: true,
      showMinutes: true,
      showSeconds: true,
    }),
    fixedHeader: {
      fixedHeader_textBeforeCount: 'Days Since Last Incident',
      fixedHeader_textBeforeCountEmoji: 'emoji_party_popper',
    },
    banner: {
      banner_beforeSubtitle: '',
      counterFigure: 'cycle',
      banner_beforeTitle: 'Days Since Last Incident',
      counterStyle: 'joined',
      banner_counterBorderColor: 'rgba(255,70,50,0.48)',
      banner_counterBorderSize: '5px',
      banner_beforeTitleFontColor: '#424242',
      banner_counterFontColor: '#ff4632',
      banner_counterBackgroundColor: 'white',
      banner_beforeTitleSpacing: '10px',
    },
  },
};

export const TIME_PERIODS = ['Days', 'Hours', 'Minutes', 'Seconds'];

export const repeatPerUserUnitMap = (quantity) => {
  const currentDate = new Date();
  return {
    I: currentDate,
    H: new Date(currentDate.getTime() + quantity * 60 * 60 * 1000),
    D: new Date(currentDate.getTime() + quantity * 24 * 60 * 60 * 1000),
    W: new Date(currentDate.getTime() + quantity * 7 * 24 * 60 * 60 * 1000),
    M: new Date(currentDate.getTime() + quantity * 30 * 24 * 60 * 60 * 1000),
    };
};
// calculate minium font size for mobile responise design
export const minSize = (maxSizeInPX, fontSize) => {
  return `${Math.min(parseInt(maxSizeInPX), parseInt(fontSize))}px`;
};
