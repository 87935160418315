import { useContext } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { classNameBannerSubtitle, varsBannerSubtitle } from './BannerSubtitle.css.ts';
import { minSize } from '../utils';
import AppContext from '../../../../context/AppContext';
import { MOBILE_MAX_FONT_SIZE } from '../../../../helpers/constants';

export function BannerSubtitle({ children }) {
  const { attributes } = useContext(AppContext);

  return (
    <div
      className={`${classNameBannerSubtitle} custom-css-subtitle`}
      style={assignInlineVars(varsBannerSubtitle, {
        color: attributes.banner_beforeSubtitleFontColor,
        fontFamily: attributes.banner_beforeSubtitleFontFamily,
        fontSize: {
          v1: minSize(MOBILE_MAX_FONT_SIZE, attributes.banner_beforeSubtitleFontSize),
          v2: attributes.banner_beforeSubtitleFontSize,
        },
        fontStyle: attributes.banner_beforeSubtitleFontStyle,
        fontWeight: attributes.banner_beforeSubtitleFontStyle,
        marginBottom: attributes.banner_beforeSubtitleSpacing,
      })}
    >
      {children}
    </div>
  );
}
