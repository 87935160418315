/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import Banner from '../display/Banner/Banner';
import Cart from '../display/Cart/Cart';
import FixedHeader from '../display/FixedHeader/FixedHeader';
import BehaviorAfterCount from '../BehaviorAfterCount/BehaviorAfterCount';
import { INTERVAL_UNIT_MS, TIMER_GOAL_BEHAVIOR, repeatPerUserUnitMap } from '../utils';

import { className, vars } from './CountdownTimer.css.ts';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import Cookie from 'js-cookie';
import { useContext, useState, useEffect } from 'react';
import AppContext from '../../../../context/AppContext';
import { isSsr } from '../../../../helpers';

const createTimerRenderer =
  ({ behaviorAfterCount, prevValue, countdownType, canShowPreview, countingUp, isStandalone, startDate }) =>
  timeProps => {
    const onViewAndCompleted = !isStandalone && timeProps.completed;
    const shouldShowPreview = (onViewAndCompleted && behaviorAfterCount !== 'display_counter' && behaviorAfterCount !== 'repeat_counter') || canShowPreview;
    const timeUntilStart = startDate - Date.now();
    const countdownNotStarted = timeUntilStart >= 0;

    if (!countingUp && shouldShowPreview) return <BehaviorAfterCount />;
    if (countdownNotStarted && !isStandalone) return null;
    switch (countdownType) {
      case 'banner':
        return <Banner {...timeProps} prevValue={prevValue} />;
      case 'cart':
        return <Cart {...timeProps} />;
      case 'fixedHeader':
        return <FixedHeader {...timeProps} />;
      default:
        return <Banner {...timeProps} prevValue={prevValue} />;
    }
  };

function isCookielessRequest() {
  return document.location.pathname.indexOf('cookieless') !== -1;
}

function setCookieSSR(cookieKey, value, exDays, useLocalStorageBackup) {
  // exDays = -1 is used to expire/remove cookie
  if (exDays > -1 && isCookielessRequest()) {
    return;
  }
  Cookie.set(cookieKey, value, { expires: exDays, sameSite: 'None', secure: true });

  // Cookie has been attempted to be set. If user_local_storage_backup, then use local storage if cookie wasn't set
  const cookieValue = Cookie.get(cookieKey);
  if (cookieValue === undefined) {
    const exdate = new Date();
    const ex_timestamp = exdate.setDate(exdate.getDate() + exDays); // This returns the expiration timestamp in unixtime 1462987106594

    if (useLocalStorageBackup) {
      try {
        localStorage.setItem(cookieKey, JSON.stringify({ value: value, expiration: ex_timestamp }));
        return;
      } catch (e) {
        debug()('Local storage error:', e);
      }
    }
  }
}

function getCookieSSR(cookieKey, useLocalStorageBackup) {
  let value = Cookie.get(cookieKey);

  if (value === undefined && useLocalStorageBackup) {
    try {
      let data = localStorage.getItem(cookieKey);
      if (!data || data === 'null') {
        return;
      }
      data = JSON.parse(data);
      // Deal with expiration
      if (data.expiration < new Date().getTime()) {
        localStorage.removeItem(cookieKey);
        return;
      } else {
        return data.value;
      }
    } catch (err) {
      debug()('No access to local storage:', err);
    }
  }
  return value;
}

export function CountdownTimer() {
  const [countdownKey, setCountdownKey] = useState(0);
  const [countdown, setCountdown] = useState(false);
  const { attributes, meta } = useContext(AppContext);
  const app_id = meta.id;
  const behavior = TIMER_GOAL_BEHAVIOR[attributes.timerGoal];
  const behaviorAfterCount = attributes[`${attributes.countdownType}_behaviorAfterCount`];
  const notDisplayCounter = behaviorAfterCount !== 'display_counter' && behaviorAfterCount !== 'repeat_counter';
  const canShowPreview = notDisplayCounter && attributes.behaviorAfterCountPreview && meta.is_standalone;

  useEffect(() => {
    if (countdown) {
      const interval = setInterval(() => {
        resetCountdown();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown]);

  function updateDates(startDate, endDate, currentDate, repeatUnit, step, timeDifference) {
    const repeatUnitMap = {
      I: () => {
        startDate.setTime(endDate.getTime());
        endDate.setTime(startDate.getTime() + timeDifference);
      },
      H: () => {
        startDate.setTime(endDate.getTime() + 3600000 * step);
        endDate.setTime(startDate.getTime() + timeDifference);
      },
      D: () => {
        startDate.setDate(endDate.getDate() + step);
        endDate.setTime(startDate.valueOf() + timeDifference);
      },
      W: () => {
        startDate.setDate(endDate.getDate() + 7 * step);
        endDate.setTime(startDate.valueOf() + timeDifference);
      },
      M: () => {
        startDate.setMonth(endDate.getMonth() + step);
        endDate.setTime(startDate.valueOf() + timeDifference);
      },
      Y: () => {
        startDate.setFullYear(endDate.getFullYear() + step);
        endDate.setTime(startDate.valueOf() + timeDifference);
      },
    };
  
    while (endDate > startDate && currentDate >= endDate) {
      if (repeatUnitMap[repeatUnit]) {
        repeatUnitMap[repeatUnit]();
        if (!(currentDate >= endDate)) break;
      } else {
        break;
      }
    }
  }

  const calculateNextEndDate = () => {
    let startDate = new Date(attributes.scheduleDates.start);
    let endDate = new Date(attributes.scheduleDates.end);
    const repeatUnit = attributes[`${attributes.countdownType}_repeatUnit`];
    const repeatQuantity = parseInt(attributes[`${attributes.countdownType}_repeatQuantity`]);
    const timeDifference = endDate - startDate;
    const currentDate = new Date();
    let step = 1 * repeatQuantity;

    // side effects on startDate and endDate
    updateDates(startDate, endDate, currentDate, repeatUnit, step, timeDifference);
    
    let scheduleDates = { start: startDate.valueOf(), end: endDate.valueOf() };
    attributes.scheduleDates = scheduleDates;
  }

  const remainingTimePerVisitor = () => {
    const repeatUnit = attributes[`${attributes.countdownType}_repeatUnit`];
    const repeatQuantity = parseInt(attributes[`${attributes.countdownType}_repeatQuantity`]);
    const nextTime = repeatPerUserUnitMap(repeatQuantity)[repeatUnit];
    setCookieSSR(`powr_countdown_v2_visitor_timeout_${app_id}`, nextTime.getTime(), 31, true);
  }

  const resetCountdownPerVisitor = () => {
    const visitorTimeout = getCookieSSR(`powr_countdown_v2_visitor_timeout_${app_id}`, true);
    const currentTime = new Date().getTime();
    if (new Date(+visitorTimeout) < currentTime) {
      const cookieKey = `powr_countdown_v2_${attributes.timerGoal}_${app_id}`;
      remainingTimePerVisitor();
      setCookieSSR(cookieKey, 1, -1, true);
    }
  }

  const resetCountdown = () => {
    if (attributes.timerGoal === 'countdownTimeVisitor' && behaviorAfterCount === 'repeat_counter') {
      resetCountdownPerVisitor();
    }
    setCountdownKey(prevKey => prevKey + 1);
    setCountdown(false);
  };

  const timeInMilliseconds = () => {
    return Date.now() + INTERVAL_UNIT_MS[attributes.perVisitorUnit] * attributes.perVisitorQuantity;
  };

  const getPrevValue = () =>
    behavior.type === 'number'
      ? {
          number: attributes.targetNumber,
        }
      : { days: 0, hours: 0, minutes: 0, seconds: 0 };

  const getTime = initialTime => {
    // code running on server
    if (isSsr()) {
      return initialTime;
    }

    // code running on client
    if (!behavior.useCookie) return initialTime;
    const cookieKey = `powr_countdown_v2_${attributes.timerGoal}_${app_id}`;
    const timeRaw = getCookieSSR(cookieKey, true);
    const visitorTimeout = getCookieSSR(`powr_countdown_v2_visitor_timeout_${app_id}`, true);
    
    if (attributes.timerGoal === 'countdownTimeVisitor' && behaviorAfterCount === 'repeat_counter' && !visitorTimeout) {
      remainingTimePerVisitor();
    }

    if (!timeRaw) {
      setCookieSSR(cookieKey, initialTime, 10, true);
      return initialTime;
    }
    return parseInt(timeRaw);
  };

  const getTimeForNumberVisitorCounter = direction => {
    const updateFrequency = INTERVAL_UNIT_MS[attributes.intervalUnit] * attributes.interval;
    const targetInTime = (updateFrequency * parseInt(attributes.targetNumber, 10)) * (direction === 'up' ? -1 : 1);
    const startTime = getTime(Date.now());
    return startTime + targetInTime;
  };

  const getTimeForNumberCounter = direction => {
    const updateFrequency = INTERVAL_UNIT_MS[attributes.intervalUnit] * attributes.interval;
    const spentTime = Number.isInteger(attributes.targetNumberCounterStartedAt) ? Date.now() - attributes.targetNumberCounterStartedAt : 0;
    const timeShift = (spentTime + updateFrequency * parseInt(attributes.targetNumber, 10)) * (direction === 'up' ? -1 : 1);
    const startTime = getTime(Date.now());
    return startTime + timeShift;
  };

  const prevValue = getPrevValue();

  const getDate = () => {
    if (attributes.timerGoal === 'countdownTimeVisitor') {
      const initialTime = timeInMilliseconds();
      return getTime(initialTime);
    }
    if (attributes.timerGoal === 'countdownNumberVisitor' || attributes.timerGoal === 'countupNumberVisitor') {
      return getTimeForNumberVisitorCounter(behavior.countDirection);
    }
    if (behavior.type === 'number') {
      return getTimeForNumberCounter(behavior.countDirection);
    }
    if (behavior.countDirection === 'up') {
      return attributes.startDate;
    }
    return attributes.scheduleDates.end;
  };

  const clearCart = () => {
    if (getCookieSSR('powr_countdown_cart_cleared_' + meta.id, true)) {
      return;
    }
    setCookieSSR('powr_countdown_cart_cleared_' + meta.id, true, 1, true);
    parent.postMessage(
      JSON.stringify({
        message: 'clearCart',
        data: {},
      }),
      '*'
    );
  }

  const onComplete = (e) => {
    if (behaviorAfterCount === 'repeat_counter' && behavior.type === 'date' && behavior.countDirection === 'down') {
      calculateNextEndDate();
      setCountdown(true);
    }
    if (attributes.countdownType !== 'cart') return;
    if (['empty_cart_hide_counter', 'empty_cart_display_message'].includes(attributes.cart_behaviorAfterCount) && !meta.is_standalone) {
      clearCart();
    }
  };

  const getStartDate = () => {
    if (['countdownTime', 'countdownDate'].includes(attributes.timerGoal))
      return Math.max(attributes.scheduleDates.start, Date.now());
    return Date.now();
  };

  return (
    <div className="countdown-timer-v2">
      <div
        className={`flex-row justify-content-center ${className} custom-css-ct-container`}
        style={assignInlineVars(vars, {
          // TODO ABC: test
          padding: attributes.countdownType === 'cart' ? attributes.counterPadding : 'unset',
        })}
      >
        <Countdown
          key={countdownKey}
          date={getDate()}
          now={getStartDate}
          renderer={createTimerRenderer({
            countdownType: attributes.countdownType,
            prevValue,
            canShowPreview,
            isStandalone: meta.is_standalone,
            countingUp: behavior.countDirection === 'up',
            behaviorAfterCount,
            startDate: getStartDate(),
            appId: meta.id,
          })}
          overtime={behavior.countDirection !== 'down'}
          onComplete={onComplete}
        />
      </div>
    </div>
  );
}

CountdownTimer.defaultProps = {
  countdownType: 'fixedHeader',
};

CountdownTimer.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]).isRequired,
  beforeTitle: PropTypes.string,
  countdownType: PropTypes.oneOf(['banner', 'cart', 'fixedHeader']),
  perVisitorUnit: PropTypes.string,
  perVisitorQuantity: PropTypes.number,
  intervalUnit: PropTypes.oneOf(['ms', 's', 'm', 'h', 'd']),
  interval: PropTypes.number,
  targetNumber: PropTypes.number,
  targetNumberCounterStartedAt: PropTypes.number,
  id: PropTypes.number,
  timerGoal: PropTypes.oneOf([
    'countdownDate',
    'countdownTime',
    'countdownTimeVisitor',
    'countdownNumberVisitor',
    'countupNumber',
    'countupNumberVisitor',
    'countupDate',
  ]),
  behaviorAfterCountPreview: PropTypes.bool,
  behaviorAfterCount: PropTypes.string,
  locals: PropTypes.object,
  scheduleDates: PropTypes.object,
  isStandalone: PropTypes.bool,
  cart_behaviorAfterCount: PropTypes.string,
};
