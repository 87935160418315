import "src/components/apps/countdown-timer/display/FixedHeader/FixedHeader.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/apps/countdown-timer/display/FixedHeader/FixedHeader.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41Uy3KjMBC8+yt0DAel8CPEZi/7J1sCDXhsIWklEeNs+d+3EITAmEO4UOoeaWZ6Wnr9s639h98fU/ZvwxjnX+ttzn4tgB0F9hQ4UOCNAhkF3ilw7IHH5vVrfYpl3VCGc84+hHuZVZj0W63xGNBoyu4i+8lRS+hydopfDwXoAhcKa52zEnQA16M34yQvHIhrzuKPC6V6ojI6cI+fkLPtwXbxBGNptn3MpqAKlDlExmF9fqLeIlWYEExDuWxoTkiJus7Zccgs0Vsl7jmrFETg0vqA1Z2XRgfQYd5RIcpr7UyrJS+NMo5meE9IFDaiBhp1TBbjEMQlBZ1fSQFJAaBARUZexxyrNRfJNJJKNKjuNKBMyMyWtJzR4a6eePjmb7A2smopx5nIgbS3CwWuFFAUaCigiT4mJl238WSYZdk4Os1JeNL0MuO4ExJbT0Ou1Cqrw1E/MlQTo1YvtF6Ka2Ofk+VRK9TAf+D8qArHAI2fw7Ww00VqhKtRxyXbZj02S/w3Jp5f/GzYtTDGMU1jJ2cMwL0VJeRMm5sTdnGYi4d9v1Ki8Ea1AaaHZKxofCHGVdk63ytsDU4v1CDZiRTrV1UqlCmvi7hAm9qlcyn4mP9Ajm+ftBgjfjcgUbCXRnR8LO2QprZL4gZi1pkxM9sNPTD22Dz+A/QOhgV/BgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var classNameContainer = '_1gsvs389';
export var classNameCounter = '_1gsvs38o';
export var classNameCounterContainer = '_1gsvs38p';
export var classNameCounterText = '_1gsvs38u';
export var classNameCounterValues = '_1gsvs38q';
export var classNameCross = '_1gsvs38r';
export var classNameFixedHeaderTimer = '_1gsvs38g';
export var classNameIcon = '_1gsvs38t';
export var classNameTextBeforeWithIcon = '_1gsvs38s';
export var varsContainer = {width:'var(--_1gsvs381)',position:'var(--_1gsvs382)',top:'var(--_1gsvs383)',left:'var(--_1gsvs384)',right:'var(--_1gsvs385)',bottom:'var(--_1gsvs386)',backgroundColor:'var(--_1gsvs387)',backgroundImage:'var(--_1gsvs388)'};
export var varsCounter = {padding:'var(--_1gsvs38i)',border:'var(--_1gsvs38j)',borderRadius:'var(--_1gsvs38k)',backgroundColor:'var(--_1gsvs38l)',backgroundImage:'var(--_1gsvs38m)',width:'var(--_1gsvs38n)'};
export var varsFixedHeaderTimer = {color:'var(--_1gsvs38b)',fontFamily:'var(--_1gsvs38c)',fontSize:'var(--_1gsvs38d)',fontStyle:'var(--_1gsvs38e)',fontWeight:'var(--_1gsvs38f)'};