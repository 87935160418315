import { useContext, useState } from 'react';
import AppContext from '../../context/AppContext';
import { PenIcon } from './icons/index';
import './styles.css';

export function EditButton({ shadowRoot }) {
  const {
    meta,
    params: { powrUrl },
  } = useContext(AppContext);
  const hashId = meta.hashid;
  const href = `${powrUrl}/plugins/${meta.app_slug}/standalone?id=${hashId}`;
  const [showButtons, setShowButtons] = useState(true);

  const toggleControls = () => {
    setShowButtons(!showButtons);
  };

  return (
    <div className='edit-me'>
      <div className={`edit-me__btn-group ${showButtons ? 'slide-down' : 'slide-up'}`}>
        <a className='edit-me__btn' href={href} target="_blank" rel="noopener noreferrer">
          <i>
            <PenIcon fill={'#fff'} />
          </i>
          <span>Edit App</span>
        </a>
        <span className='edit-me__hint'>(Only you can see this)</span>
        <span className='edit-me__hint edit-me__hint-control' onClick={toggleControls}>Hide</span>
      </div>

      <div className={`edit-me__btn-group ${showButtons ? 'edit-me__hid' : ''}`}>
        <span className='edit-me__hint edit-me__hint-control' onClick={toggleControls}>Show Controls</span>
      </div>
    </div>
  );
}
