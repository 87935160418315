import { useContext } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { classNameButtonLink, varsButtonLink } from './ButtonLink.css.ts';
import AppContext from '../../../../context/AppContext';
import { multiplySize, minSize } from '../utils';

export function ButtonLink(props) {
  const { attributes } = useContext(AppContext);
  return (
    <a
      href={props.href}
      target={props.target}
      className={`${classNameButtonLink} custom-css-btn-link`}
      style={assignInlineVars(varsButtonLink, {
        backgroundColor: attributes.buttonColor,
        color: attributes.buttonFontColor,
        fontFamily: attributes.buttonFontFamily,
        fontSize: attributes.buttonFontSize,
        fontStyle: attributes.buttonFontStyle,
        fontWeight: attributes.buttonFontStyle,
        paddingLeft: multiplySize(attributes.buttonSize, 2),
        paddingRight: multiplySize(attributes.buttonSize, 2),
        borderRadius: attributes.borderRadius,
        ':link': {
          color: `${attributes.buttonFontColor} !important`,
        },
      })}
    >
      {props.children}
    </a>
  );
}
