import { isPlatform } from './index';
export const plans = ['free', 'premium', 'pro', 'enterprise'];
export const usagePlans = ['v_250', 'v_500', 'v_2500', 'v_5000', 'v_10000', 'v_25000', 'v_50000', 'custom'];

// code below were copied from powr repo
// USAGE BASED PRICING CODE
const isInUsageBasedV2App = (meta, currentUser) => {
  if (meta) {
    if (meta.premium_status) return usagePlans.includes(meta.premium_status);
    if (typeof window !== undefined) {
      return usagePlans.includes(window.PREMIUM_STATUS); // case for powrzilla
    }
  }
  return isInUsageBasedV2Group(currentUser);
};

// USAGE BASED PRICING CODE
const isInUsageBasedV2Group = currentUser => {
  if (currentUser) return currentUser['is_usage_based?'] !== null;
  // return isInABTest('usage_based_pricing_v2', 'usage_based');
};

const UpgradeHelper = {
  isUpgradeRequired: (userStatus, requiredStatus, meta, globals) => {
    // USAGE BASED PRICING CODE
    if (isPlatform() || isInUsageBasedV2App(meta, globals?.CURRENT_USER)) {
      return false;
    }
    return plans.indexOf(userStatus) < plans.indexOf(requiredStatus);
  },
};

export default UpgradeHelper;
