import { classNameTrialEndOverlay, classNameTrialEndOverlayMessage, classNameTrialEndOverlayButton } from './TrialEndOverlay.css.ts';
import { useContext } from 'react';
import AppContext from '../../context/AppContext';

export function TrialEndOverlay() {
  const {
    params: { powrOneUrl },
  } = useContext(AppContext);
  return (
      <div className={classNameTrialEndOverlay}>
        <div className={classNameTrialEndOverlayMessage}>
          <h3>Your trial expired. Please select a plan to continue using POWR One</h3>
          <a href={powrOneUrl} target="_blank" rel="noopener noreferrer" className={classNameTrialEndOverlayButton}>View plans</a>
          <p>(Only you can see this)</p>
        </div>
      </div>
  );
}
